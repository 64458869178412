import React from "react";
import Doctor from "../Assets/doctor-book-appointment.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate  } from "react-router-dom";
import "../Styles/BookAppointment.css";

function BookAppointment() {
  const navigate = useNavigate();

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  return (
    <div className="ba-section">
      <div className="ba-image-content">
        <img src={Doctor} alt="Doctor Group" className="ba-image1" />
      </div>

      <div className="ba-text-content">
        <h3 className="ba-title">
          <span>¿Por qué elegirnos?</span>
        </h3>
        <p className="ba-description" style={{ textAlign: 'justify' }}>
        En Legal Services Enterprise, abrimos puertas hacia tu próximo capítulo. Con nuestra experiencia en trámites migratorios, simplificamos el proceso 
        y te brindamos el respaldo que necesita para avanzar con confianza. No es solo un servicio, es un compromiso de acompañarlo hacia tus metas con soluciones 
        diseñadas para tu éxito.
        </p>

        <p className="ba-checks ba-check-first">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Asesoría Experta y Especializada
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Acompañamiento en Cada Paso
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Soluciones Personalizadas y Accesibles
        </p>
        <p className="ba-checks ba-check-last">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Compromiso con tu Futuro
        </p>

       
      </div>
    </div>
  );
}

export default BookAppointment;
