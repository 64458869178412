import React, { useEffect, useState } from "react";
import Doctor from "../Assets/doctor-picture.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate  } from "react-router-dom";
import "../Styles/Hero.css";

function Hero() {
  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
      <div className="hero-section">
        <div className="text-section">
          <p className="text-headline">Tus preparadores de confianza</p>
          <h2 className="text-title">
            Legal Services Enterprise
          </h2>
          <p className="text-descritpion" style={{ textAlign: 'justify' }}>
          Confía en un servicio completo que abarca cada aspecto de tu proceso migratorio. Te brindamos apoyo personalizado en cada solicitud, 
          asegurando que avance sin estrés ni complicaciones. Simplificamos los trámites para que solo te enfoques en tu próximo paso hacia 
          un futuro prometedor.
          </p>
          
          <div className="text-stats">
            <div className="text-stats-container">
              
            </div>

            <div className="text-stats-container">
              
            </div>

            <div className="text-stats-container">
            
            </div>
          </div>
        </div>

        <div className="hero-image-section">
          <img className="hero-image1" src={Doctor} alt="Doctor" />
        </div>
      </div>

      <div
        onClick={scrollToTop}
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    </div>
  );
}

export default Hero;
