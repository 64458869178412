import React from "react";
import InformationCard from "./InformationCard";
import { faHeartPulse, faTruckMedical, faTooth } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>¿En qué destacamos?</span>
        </h3>
        <p className="info-description" style={{ textAlign: 'justify' }}>
        Destacamos por nuestra dedicación y precisión en cada trámite migratorio, asegurándonos de que cada formulario y solicitud esté completa y 
        correctamente preparada. Nuestro equipo se compromete a ofrecerte una experiencia sin complicaciones, respaldándote en cada etapa del proceso. 
        Además, nos diferenciamos por nuestra atención personalizada: escuchamos tus necesidades y adaptamos nuestro servicio para que logres tus metas con confianza 
        y tranquilidad. En Legal Services Enterprise, tu éxito en cada paso del camino es nuestra prioridad.
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="¿Cómo puedo asegurarme de que mis formularios migratorios se completen correctamente?"
          description={
            <p style={{ textAlign: 'justify' }}>
              Con nuestro equipo de expertos en trámites migratorios, cada formulario se revisa y se completa con precisión para evitar errores y retrasos. 
              Nos aseguramos de que toda la información cumpla con los requisitos necesarios, brindándote la tranquilidad de saber que estás en buenas manos.
          </p>
        }
          icon={faHeartPulse}
        />

        <InformationCard
          title="¿Qué tipo de asistencia puedo esperar durante el proceso de solicitud?"
          description={
            <p style={{ textAlign: 'justify' }}>
              Estamos contigo en cada paso. Desde la preparación de documentos hasta el seguimiento de tus solicitudes, nuestro equipo te brinda una asistencia 
              completa, respondiendo a tus preguntas y adaptando nuestro apoyo según tus necesidades para que te sientas respaldado en cada etapa del proceso.
            </p>
          }
          icon={faHeartPulse}
        />

        <InformationCard
          title="¿Cuáles son las ventajas de trabajar con Legal Services Enterprise en lugar de hacerlo solo?"
          description={
            <p style={{ textAlign: 'justify' }}>
              Con nosotros, ganas experiencia, rapidez y precisión. Nuestro conocimiento en temas migratorios te ahorra tiempo y reduce el estrés de lidiar 
              con trámites complejos por tu cuenta. Nos ocupamos de los detalles, para que tú puedas concentrarte en lo que importa: construir un futuro con 
              nuevas oportunidades.
          </p>
          }
          icon={faHeartPulse}
        />
      </div>
    </div>
  );
}

export default Info;
