import React from "react";
import Doctor from "../Assets/doctor-group.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={Doctor} alt="Doctor Group" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>Sobre nosotros</span>
        </h3>
        <p className="about-description" style={{ textAlign: 'justify' }}>
        ¡Somos Legal Services Enterprise, tu aliado confiable en cada paso del camino migratorio! Accede a un servicio integral 
        para la preparación de formularios, asistencia en solicitudes y beneficios únicos. Nos enfocamos en hacer tu proceso más 
        simple y seguro, cuidando cada detalle para que puedas avanzar con confianza hacia tus metas.
        </p>

        <h4 className="about-text-title">Legal Services Enterprise</h4>

      
      </div>
    </div>
  );
}

export default About;
