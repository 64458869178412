import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Legal from "./Pages/Legal";
import Cookies from "./Pages/Cookies";
import Privacy from "./Pages/Privacy";
import NotFound from "./Pages/NotFound";
import Appointment from "./Pages/Appointment";

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms-and-conditions" element={<Legal />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/cookies-use-policy" element={<Cookies />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
